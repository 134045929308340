body {
    color: #313238;
    font-family: 'Roboto', sans-serif;
}

.banner {
    background: white;
    padding: 10px 0;
    font-family: 'Dax Condensed Regular';
    text-align: right;
    width: 100%;
    z-index: 999;
    border-bottom: 1px solid #d2d2da;
}

.banner .logotype {
    width: 140px;
    height: 34px;
}

.banner a, 
.banner a:hover {
    color: #0f3960;
    text-decoration: none;
}

h1 {
    font-family: 'Dax Condensed Bold';
    font-size: 30px;
    line-height: 40px;
    max-width: 700px;
    margin: 15px auto;
}

h2 {
    font-family: 'Dax Condensed Regular';
    font-size: 30px;
    margin: 8px 0;
    margin-bottom: 16px;
}

h3 {
    font-family: 'Dax Condensed Bold';
    font-size: 20px;
    margin: 0;
    padding-bottom: 10px;
}

h4 {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

p {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin: 0;
    font-weight: 300;
    line-height: 25px;
}

p.description {
    width: 60%;
}

@media only screen and (max-width: 700px) {
    p.description {
        width: 100%;
    }
}

p.large {
    font-size: 16px;
    max-width: 800px;
    margin: 0px auto;
}

a, a:hover {
    color: #2b91cb;
    text-decoration: underline;
}

a.in-text {
    color: #fff;
}

.bold {
    font-weight: 400;
}

.buttons-wrapper button {
    margin-top: 25px;
}

button {
    font-family: 'Dax Condensed Regular';
    cursor: pointer;
    padding: 15px 50px;
    background-color: #2d3a44;
    color: #fff;
    border: 1px solid #fff;
    font-size: 18px;
    outline: 0;
    transition: .5s background-color;
}

button:hover {
    background-color: #fff;
    color: #2d3a44;
}

.highlight-color {
    color: #F4D500
}

.highlight-color.small {
    font-size: 30px;
}

.inner {
    max-width: 1200px;
    margin: 0px auto;
    padding-left: 25px;
    padding-right: 25px;
}

.inner-right {
    display: inline-block;
    max-width: 50%;
    margin-left: auto;
    padding: 80px 50px 60px;
    animation: 3s opacity;
}

.inner.flex {
    display: flex;
    align-items: center;
}

.inner.flex .right {
    margin-left: auto;
}

.content {
    background: #ececec;
    padding: 50px 0;
    position: relative;
    overflow: hidden;
}

.content.tall {
    padding: 100px 0;
}

.shadow-holder {
    width: 150%;
    height: 100%;
    position: absolute;
    box-shadow: inset 0 0 11px #b3b2b28c;
    top: 0;
    right: -50px;
}


@media only screen and (max-width: 700px) {
    .content.tall {
        padding: 25px 0;
    }
}

.content.dark {
    background: #2d3a44;
    color: #fff;
    padding: 0;
    text-align: center;
}

.startpage-inner {
    position: relative;
    display: flex;
    max-width: 1200px;
    margin: 0px auto;
}

.startpage-image {
    position: absolute;
    width: 48%;
    height: 100%;
    top: 0;
    background-image: url("../img/startpage.jpg");
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 62% 100%, 0% 100%);
    background-position: top right;
}

@media only screen and (max-width: 820px) {
    .startpage-image  {
        display: none;
    }

    .inner-right {
        max-width: 100%;
        padding: 30px 25px 40px;
    }
}


.overview-container {
    padding-top: 40px;
    padding-bottom: 40px;
}

@media only screen and (max-width: 700px) {
    .overview-container {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.overview-list {
    list-style: none;
    justify-content: space-between;
    padding-bottom: 40px;
}

.overview-list.inner.flex {
    align-items: normal;
}

.overview-list a {
    text-decoration: none;
    color: #313238;
}

.overview-list .item {
    max-width: 350px;
    max-width: 31%; /* CHANGE HERE DEPENDING ON NO OF COLUMNS */ 
    width: 31%; /* CHANGE HERE DEPENDING ON NO OF COLUMNS */ 
    position: relative;
    box-shadow: 0 4px 27px 0 rgb(30 29 31 / 8%);
    cursor: pointer;
    transition: all 0.4s;
    border: 1px solid #efeaea;
    position: relative;
    overflow: hidden;
}

@media only screen and (max-width: 700px) {
    .overview-list.inner.flex {
        flex-direction: column;
        padding: 0 25px;
    }

    .overview-list .item {
        width: 100%;
        max-width: 100%;
        margin: 20px 0;
    }
}

.overview-list .item:hover {
    box-shadow: 0 4px 27px 0 rgb(30 29 31 / 16%);
}

.overview-list .item .read-more-link {
    opacity: 0;
    position: absolute;
    top: 20px;
    right: 0;
    background: #ffb613;
    z-index: 20;
    padding: 10px 9px 9px 9px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: #0d3860;
    box-shadow: -1px 1px 1px #a27612;
    padding-left: 20px;
    transition: .5s opacity;
}

.overview-list .item:hover .read-more-link {
    opacity: 1;
}

.overview-list .item .img-wrapper {
    display: block;
    max-width: 350px;
    height: 350px;
    position: relative;
    margin: 0px auto;
}

.overview-list .item img {
    position: absolute;
    width: 100%;
    object-fit: scale-down;
    object-position: center center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.overview-list .item .img-text {
    padding: 15px;
    display: block;
    border-top: 1px solid #efeaea;
}

.overview-list .item .img-text h3 {
    padding: 0 0 5px 0;
}

.more-products {
    padding-bottom: 40px;
}

.long-inner {
    max-width: 1200px;
    margin: 0px auto;
}

.product-overview {
    padding: 30px 25px 50px;
    max-width: 1200px;
    margin: 0px auto;
}

.product-list.short {
    border-top: 0;
}

.product-list.short li {
    border-top: 1px solid #dad2d2;
}

.product-list {
    margin: 25px 0 0 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    border-left: 1px solid #dad2d2;
    border-top: 1px solid #dad2d2;
}

.product-list li {
    list-style: none;
    width: calc(100% / 3);
    border-bottom: 1px solid #dad2d2;
    background: white;
}

.product-list.interior-signage li {
    width: calc(100% / 3);
}

.product-list .no {
    position: absolute;
    top: 8px;
    left: 8px;
    font-size: 13px;
    color: #b6b8bf;
    font-family: 'Dax Condensed Regular';
}

@media only screen and (max-width: 700px) {
    .product-list li,
    .product-list.interior-signage li {
        width: calc(100% / 2);
    }
}

.product-list li .inner-border {
    border: 1px solid #dad2d2;
    border-top: none;
    border-bottom: none;
    border-left: none;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
    position: relative;
    text-align: center;
}

.product-list li:last-of-type .inner-border {
    border-right: 1px solid #dad2d2;
}

.product-list li img {
    width: 100%;
    max-width: 340px;
}

.product-list li .img-text {
    border-top: 1px solid #dad2d2;
    padding: 15px 0;
    display: block;
    text-align: left;
}

.product-list li .img-text h4,
.product-list li .img-text p {
    padding: 0 10px;
    margin: 0;
}

.product-list li .img-text h4 {
    padding-bottom: 3px;
    word-break: break-word;
}

.product-list li .img-text p {
    font-size: 14px;
    font-weight: 300;
}

.form-container {
    position: relative;
}

form {
    margin-top: 30px;
}

form label {
    display: block;
    font-weight: 400;
    padding-bottom: 5px;
}

form label.required:after {
    content: "*";
    padding-left: 5px;
    color: #cb2b31;
}

form input, 
form textarea {
    box-sizing: border-box;
    height: 50px;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #dad9d9;
    width: 100%;
    font-weight: 300;
    font-size: 18px;
    border-radius: 3px;
    -webkit-appearance: none;
}

form textarea {
    height: 100px;
}

form input:focus,
form textarea:focus {
    border: 2px solid #2b91cb;
    outline: 0;
}

form input[type="submit"] {
    background: #2b91cb;
    color: white;
    font-weight: 800;
    letter-spacing: 1px;
    cursor: pointer;
    border: none;
}

form .row {
    display: flex;
    justify-content: space-between;
}

form .col {
    width: 49%;
}

.confirmation {
    display: none;
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%; 
    height: 100%;
    text-align: center;
}

.footer {
    padding: 50px 0;
    background: #0c2735;
    color: white;
    font-weight: 300;
    font-size: 14px;
}

.footer .logotype {
    width: 100px;
    height: 33px;
}

@media only screen and (max-width: 700px) {
    .footer .inner.flex {
        display: block;
    }

    .footer .inner.flex .right {
        padding-top: 50px;
        text-align: center;
    }
}

.img-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1220px;
    padding: 0 25px;
    margin: 50px auto;
}

.img-container p.description {
    width: 100%;
    padding: 0 5px 20px 5px;
}

.img-container:hover img { opacity: 0.7; }
.img-container:hover img:hover { opacity: 1; } 

.img-container .image {
    margin: 5px;
    background: black;
}

.img-container .image.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .8s opacity;
}

.img-container > .image.normal {
    flex: 1 1 150px;
}

.img-container > .image.big {
    flex: 1 1 250px;
}

@media only screen and (max-width: 700px) {
    .img-container > .image.normal {
        flex: 1 1 50px;
    }

    .img-container > .image.big {
        flex: 1 1 50px;
    }
}

.cookie-banner {
    position: fixed;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
    top: 0;
    text-align: center;
}

.cookie-inner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    background: rgba(255, 255, 255, 0.98);
    border-radius: 10px;
    padding: 30px;
}

.cookie-inner button {
    margin-top: 20px;
    white-space: nowrap;
}

.cookie-inner button:hover {
    background-color: #08213a;
    color: #fff;
}

.back {
    font-size: 14px;
    padding-bottom: 20px;
}

.click-on-image {
    margin: 20px 0 50px 0;
    display: flex;
    justify-content: space-between;
}

.click-on-image .night {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    transition: 2.0s opacity;
}

.click-on-image .image-left:hover > .night,
.click-on-image .image-right:hover > .night {
    opacity: 1;
}

.click-on-image .image-left {
    max-width: 666px;
    width: 55%;
}

.click-on-image .image-right {
    max-width: 509px;
    width: 42%;
}

.click-on-image .image-left,
.click-on-image .image-right {
    position: relative;
}

.click-on-image .image-left img,
.click-on-image .image-right img {
    width: 100%;
}

.extras {
    display: flex;
    align-items: center;
    background: #def3ff;
    margin-top: 50px;
    padding: 50px;
}

.extras .image {
    max-width: 120px;
    padding-right: 50px;
}

.extras .image img {
    width: 100%;
}


@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.newsletter-button {
    position: fixed;
    top: 100px;
    right: 0;
    background: #2b91cb;
}

.newsletter-button .inner {
    transform: rotate(270deg);
}



.overview-container .two-col-matrix {
    display: flex;
    width: 80%;
    background: white;
    box-shadow: 0 4px 27px 0 rgb(30 29 31 / 8%);
    margin: 0px auto;
    border: 1px solid #efeaea;
}

.overview-container .two-col-matrix:last-of-type {
    border-top: none;
}

.overview-container .two-col-matrix .col {
    width: 50%;
    display: flex;
    align-items: center;
}

.overview-container .two-col-matrix .col p {
    width: 100%;
    text-align: center;
    padding: 25px;
    line-height: 30px;
}

.overview-container .two-col-matrix:nth-child(even) .col:first-child {
    margin-left: auto;
    order: 2;
}

@media only screen and (max-width: 700px) {
    .overview-container .two-col-matrix {
        width: 100%;
        margin-top: 25px;
        display: block;
    }

    .overview-container .two-col-matrix .col {
        width: 100%;
    }
}